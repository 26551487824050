import * as React from "react";
import Layout from "src/components/layout";
import abeAndRose from "src/images/abraham-rose.jpg";
import kenneth from "src/images/ken-spetner.jpg";

const AboutPageComponent = ({ copy, pageTitle, location, lang }) => {
  return (
    <Layout pageTitle={pageTitle} location={location} lang={lang}>
      <article className="inner-content my-20 md:flex md:flex-row-reverse mx-auto">
        <section className="flex flex-col md:w-1/2 text-center gap-16">
          <figure className="self-center">
            <img src={abeAndRose} alt={copy.abeAndRose} className="mx-auto" />
            <figcaption className="my-2">{copy.abeAndRose}</figcaption>
          </figure>
          <figure className="self-center">
            <img src={kenneth} alt={copy.kenneth} className="mx-auto" />
            <figcaption className="my-2">{copy.kenneth}</figcaption>
          </figure>
        </section>
        <section className="text-justify w-2/3 mx-auto mt-10 md:mx-10 md:mt-0 md:w-1/2 leading-7 text-lg">
          {copy.text.map((p, key) => (
            <p key={key} className="mb-4">
              {p}
            </p>
          ))}
        </section>
      </article>
    </Layout>
  );
};

export default AboutPageComponent;
