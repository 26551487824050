import * as React from "react";
import AboutPageComponent from "src/components/pages/about-us";

const AboutPage = ({ location }) => {
  const copy = {
    abeAndRose: "אברהם ורוז ספטנר",
    kenneth: "קהת ספטנר",
    text: [
      `אברהם ורוז ספטנר מכרו את פוליסת הביטוח הראשונה שלהם בשנת 1935.
      יחד הם בנו עסק ע”י נתינת פתרונות ביטוח למגוון
      רחב של צרכים במחירים שווים לכל כיס. הם בנו מוניטין של מקצועיות
      ויושר, והעסק שלהם צמח.`,
      `למרות שדברים רבים השתנו מאז 1935 דור שלישי
      ורביעי ממשיכים לתפעל את ספטנר עם אותם המטרות: אכפתיות ללקוחות
      ונתינת פתרונות ביטוח החיים הטובים ביותר. בין אם אתה מחפש ביטוח חיים
      תקופתי זמני(ריסק), ביטוח לכל החיים או משהו באמצע, ספטנר יעזור לך
      לנווט בים האפשרויות של פתרונות ביטוחי החיים ופרוצדורות החיתום
      בכדי למצוא את הפתרונות שישרתו אותך הכי טוב.`,
      `יש לנו התמקצעות מיוחדת
      בתחום החיתום הבינלאומי ואנו מבטיחים למצוא בשבילך את הפתרונות הכי
      חסכוניים עבור הצרכים שלך.`,
    ],
  };
  return (
    <AboutPageComponent
      lang="he"
      location={location}
      copy={copy}
      pageTitle="אודות"
    />
  );
};

export default AboutPage;
